@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@400;700&display=swap");
body {
  font-family: "Chivo", sans-serif;
}

/* Swiper.js Pagination custom styles */
.swiper-pagination-bullet {
  background-color: #4a5568; /* Customize bullet color */
  opacity: 0.7;
}
.swiper-pagination-bullet-active {
  background-color: #3182ce; /* Active bullet color */
  opacity: 1;
}

/* Swiper.js Navigation arrows custom styles */
.swiper-button-next,
.swiper-button-prev {
  color: #3182ce; /* Arrow color */
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #2b6cb0; /* Arrow color on hover */
}
/* Navbar.css */
nav {
  backdrop-filter: blur(10px); /* Adjust the blur intensity as needed */
}
